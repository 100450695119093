@use 'styles/variables' as var;
@use 'styles/functions' as fn;

$breakpoints: (
  xxl: var.$breakpoint-xxl,
  xl: var.$breakpoint-xl,
  lg: var.$breakpoint-lg,
  md: var.$breakpoint-md,
  sm: var.$breakpoint-sm,
  xs: var.$breakpoint-xs,
  xxs: var.$breakpoint-xxs,
);

$display-styles: (
  bodyXs: (font-family: var(--font-ultramarine), font-weight: 400, font-size: 12px, line-height: 1.4),
  bodySm: (font-family: var(--font-ultramarine), font-weight: 400, font-size: 14px, line-height: 1.4),
  bodyMd: (font-family: var(--font-ultramarine), font-weight: 400, font-size: 16px, line-height: 1.4),
  bodyLg: (font-family: var(--font-ultramarine), font-weight: 400, font-size: 18px, line-height: 1.4),
  labelSm: (font-family: var(--font-ultramarine), font-weight: 500, font-size: 14px, line-height: 1.2),
  labelMd: (font-family: var(--font-ultramarine), font-weight: 500, font-size: 16px, line-height: 1.2),
  labelLg: (font-family: var(--font-ultramarine), font-weight: 500, font-size: 18px, line-height: 1.2),
  headingSm: (font-family: var(--font-ultramarine), font-weight: 700, font-size: 18px, line-height: 1.3),
  headingMd: (font-family: var(--font-ultramarine), font-weight: 700, font-size: 24px, line-height: 1.3),
  headingLg: (font-family: var(--font-ultramarine), font-weight: 700, font-size: 32px, line-height: 1.3),
  headingXl: (font-family: var(--font-ultramarine), font-weight: 700, font-size: 40px, line-height: 1.3),
  displayXs: (font-family: var(--font-ultramarine), font-weight: 700, font-size: 16px, line-height: 1.4),
  displaySm: (font-family: var(--font-ultramarine), font-weight: 500, font-size: 20px, line-height: 1.2),
  displayMd: (font-family: var(--font-ultramarine), font-weight: 500, font-size: 28px, line-height: 1.2),
  displayLg: (font-family: var(--font-ultramarine), font-weight: 500, font-size: 36px, line-height: 1.2),
  displayXl: (font-family: var(--font-ultramarine), font-weight: 500, font-size: 48px, line-height: 1.2),
);

@mixin responsive($class, $properties) {
  .#{$class} {
    @each $property, $value in $properties {
      #{$property}: #{$value};
    }
  }
}

@each $class, $properties in $display-styles {
  @include responsive($class, $properties);
}

@mixin responsiveMediaQuery($class, $properties) {
  @each $breakpoint, $value in $breakpoints {
    @media (min-width: #{$value}) {
      .#{$breakpoint}\:#{$class} {
        @each $property, $value in $properties {
          #{$property}: #{$value};
        }
      }
    }
  }
}

@each $class, $properties in $display-styles {
  @include responsiveMediaQuery($class, $properties);
}